<template>
  <div v-if="config.length">
    <!-- Category-->
    <b-card
      v-for="(section, i) in config"
      :key="`section-${i}`"
      :header="section.category"
      header-tag="header"
      class="mt-1"
    >
      <!-- Section Overlay -->
      <b-overlay center :show="section.isLoading" rounded="sm">
        <!-- Upload Fields -->
        <b-card-text v-for="(upload, j) in section.upload" :key="`upload-${j}`">
          <p v-b-toggle="`${section.type}-${j}`" variant="primary">
            {{ upload.title }}
          </p>
          <b-collapse :id="`${section.type}-${j}`">
            <!-- File -->
            <b-card
              v-for="(file, k) in upload.files"
              :key="`files-${section.type}-${k}`"
              class="mt-2"
            >
              <!-- File Overlay -->
              <b-overlay center :show="file.isLoading" rounded="sm">
                <b-card-text>
                  <b-table :fields="fields" :items="[file]">
                    <template #cell(title)="data">
                      {{ data.item.import_file_code }}
                    </template>
                    <template #cell(filesystem)="data">
                      <div class="mt-3">
                        <base-form-file
                          v-model="data.item.value"
                          vid="base_form_file"
                          :disabled="data.item.isStored"
                        ></base-form-file>
                      </div>
                    </template>
                    <template #cell(upload)="data">
                      <b-button
                        variant="lisaweb"
                        size="sm"
                        @click="uploadFile(data, upload)"
                        :disabled="data.item.isStored || !data.item.value"
                        title="Carica File"
                      >
                        Carica
                      </b-button>
                    </template>
                    <template #cell(delete)="data">
                      <b-button
                        size="sm"
                        variant="lisaweb"
                        @click="deleteFile(data)"
                        :disabled="!data.item.isStored"
                        title="Elimina file caricato"
                      >
                        Elimina
                      </b-button>
                    </template>
                    <template #cell(check)="data">
                      <b-icon
                        icon="check-square"
                        scale="2"
                        variant="success"
                        title="File caricato"
                        v-if="data.item.isStored"
                      >
                      </b-icon>
                      <b-icon
                        icon="x-circle"
                        scale="2"
                        variant="danger"
                        title="File non caricato"
                        v-if="!data.item.isStored"
                      >
                      </b-icon>
                    </template>
                  </b-table>
                </b-card-text>

                <template #overlay>
                  <div class="text-center">
                    <base-icon name="loading" width="35" height="35" />
                    <p id="cancel-label">Operazione in corso...</p>
                  </div>
                </template>
              </b-overlay>
            </b-card>
            <b-button
              class="mt-2"
              size="sm"
              variant="lisaweb"
              title="Inizia Trasmissione"
              @click="init(section, upload)"
              :disabled="!checkStoredFiles(upload)"
            >
              Inizia Trasmissione
            </b-button>
          </b-collapse>
        </b-card-text>
        <template #overlay>
          <div class="text-center">
            <base-icon name="loading" width="35" height="35" />
            <p id="cancel-label">Operazione in corso...</p>
          </div>
        </template>
      </b-overlay>
    </b-card>
  </div>
  <div v-else>
    <p>Non ci sono file da importare</p>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseFormFile from "@/components/form/BaseFormFile";

export default {
  props: {
    config: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "title",
          thStyle: {
            display: "none",
          },
          tdClass: "col-sm-2",
        },
        {
          key: "filesystem",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-2",
        },
        {
          key: "upload",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-1",
        },
        {
          key: "delete",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-1",
        },
        {
          key: "check",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-1",
        },
      ],
      form: {
        file: null,
      },
    };
  },
  components: {
    BaseIcon,
    BaseFormFile,
  },
  methods: {
    uploadFile(file, section) {
      this.$emit("upload", file, section);
    },
    deleteFile(file) {
      this.$emit("delete", file);
    },
    init(section, upload) {
      this.$emit("init", section, upload);
    },
    checkStoredFiles(upload) {
      let fileStatuses = [];
      upload.files.forEach((file) => {
        fileStatuses.push(file.isStored);
      });
      return fileStatuses.every((status) => status);
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.table) {
  td {
    border-top: 0px !important;
  }
  th {
    border-top: 0px !important;
  }
}
</style>
