<template>
  <div class="mt-1 mx-3">
    <div v-if="tabs.length">
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <importer :importer="tab"></importer>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else>Non ci sono importatori attivi</div>
  </div>
</template>

<script>
import Importer from "@/components/importer/index.vue";
import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";
export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "module.IMP",
      tabs: [],
      tabsGroup: "Management",
      bcs: [
        {
          text: "Import",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Importatori",
          path: "",
          level: 1,
        },
        {
          text: null,
          path: "module.IMP",
          tab: `#${this.selected}`,
          level: 2,
        },
      ],
      maxLevel: 2,
      selected: null,
      tabIndex: 0,
    };
  },
  components: {
    Importer,
  },
  created() {
    // Get agencyinsurers
    const insurers = this.$store.state.auth.insurers;
    // Get agency importers
    this.tabs = insurers
      .filter((insurer) => insurer.importer)
      .map((insurer) => {
        return {
          text: insurer?.importer?.title,
          name: insurer?.importer?.title,
          code: insurer?.importer?.code,
          id: insurer?.importer?.id,
        };
      })
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
    // Set dynamic bcs
    if (this.tabs.length) {
      const title = this.tabs[0].text;
      this.selected = title;
      const bc = this.bcs.find((bc) => bc.level === 2);
      this.$set(bc, `text`, title);
    }
  },
};
</script>
