<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div class="mx-1" v-if="canVerb(resource, 'index')">
      <importer-form
        :config="config"
        @upload="uploadFile"
        @delete="deleteFile"
        @init="startTransmission"
      ></importer-form>
    </div>
    <div v-else class="mt-3">
      <b-icon icon="info-circle" scale="1.00"></b-icon>
      Non hai i permessi
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { canVerb } from "@/utils/permissions";
import ImporterForm from "@/components/form/ImporterForm";
import BaseIcon from "@/components/BaseIcon";
import ImporterMixin from "@/mixins/ImporterMixin";

export default {
  mixins: [ImporterMixin],
  name: "Importer",
  data() {
    return {
      resource: "importers",
      reposiotry: "importer",
      isLoading: false,
      config: [],
    };
  },
  props: {
    importer: {
      default() {
        return {};
      },
      type: Object,
    },
  },
  methods: {
    canVerb,
  },
  components: {
    BaseIcon,
    ImporterForm,
  },
  created() {
    this.fetch();
  },
};
</script>
